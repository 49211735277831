<template>
  <div class="natural lighten-4 fill-height">
    <SingleAppBar title="กล่องข้อความ" :backward="false" />
    <v-main>
      <v-container>
        <EmptyStateCard v-if="!items" />
        <InboxList v-else :inbox="items" />
        <!-- <ListCard/> -->
      </v-container>
       <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
      </v-main
    >
  </div>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar";
import EmptyStateCard from "@/components/inbox/EmptyStateCard";
import InboxList from "@/components/inbox/InboxList";
import {announcement,announcementBanner, getNewsInbox, getNewsFile} from "@/api/"
//import ListCard from "@/components/inbox/ListCard";

export default {
  name: "Inbox",
  components: {
    SingleAppBar,
    EmptyStateCard,
    InboxList,
    //ListCard
  },
  data() {
    return {
      state: false,
      items: [],
      loading: false,
      role: null
      
    }
  },
  methods: {
     getNextNews() {
      window.onscroll = async () => {
        let bottomOfWindow = Math.ceil(document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          try {
            this.loading = true
            let news = await getNewsInbox({offset: this.items.length,limit: 4})
            if (news.data.code === 1) {
            for(let i =0 ;i<news.data.result.items.length;i++) {
            let resp  = await getNewsFile({fileName: news.data.result.items[i].bannerName})
            if(resp.data.code === 1) {
              news.data.result.items[i].img = resp.data.result.url
            }
            this.items.push(news.data.result.items[i])
            }
            // this.items.concat(news.data.result.items)
            // console.log(news.data.result.items)
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.loading = false
          }
        }
      }
    },
    async getInitialNews () {
      try {
        this.loading = true
        let news = await getNewsInbox({offset: 0,limit: 3})
        if (news.data.code === 1) {
          for(let i =0 ;i<news.data.result.items.length;i++) {
            let resp  = await getNewsFile({fileName: news.data.result.items[i].bannerName})
            if(resp.data.code === 1) {
              news.data.result.items[i].img = resp.data.result.url
            }
          }
          this.items = news.data.result.items
        } else {
          this.items = []
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async fetchData(){
      this.role = this.$offlineStorage.get('role')
      const site = this.$offlineStorage.get("site");
      const tenant = this.$offlineStorage.get("tenant");
      if (navigator.onLine){
           this.loading = true
        try{    
        let itemresp = await announcement({tenantId: tenant.tenantId ,siteId: site.siteId,offset:0,limit:100 })
        for(let i =0 ;i<itemresp.data.result.length;i++){
          let resp = await announcementBanner({tenantId: tenant.tenantId ,siteId: site.siteId,bannerName: itemresp.data.result[i].bannerName})
          itemresp.data.result[i].img = resp.data.result
        }
        this.items = itemresp.data.result
        }catch(err){
          console.log(err)
        }finally{
          this.loading = false
        }
      }else{
         this.loading = true
         this.items =  this.$offlineStorage.get('announcement')
         this.loading = false
      }
    
    }
  },
  mounted () {
    this.getNextNews()
  },
  destroyed () {
    window.onscroll = undefined
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        // this.fetchData()
        this.getInitialNews()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
};
</script>

<style lang="scss" scoped>

</style>