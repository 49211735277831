<template>
  <v-card @click="goToDetails()">
      <v-card-text>
          <v-card elevation="0">
              <v-img
      class="white--text align-end"
      height="200px"
      :src="item.img || require('@/assets/messageAvatar.svg')"
    ></v-img>
          </v-card>
          <v-list three-line>
                <v-list-item style="padding: 0px">
                   <template v-slot:default="{ active }">
                <v-list-item-avatar v-if="!active" size="40" color="natural lighten-3"><v-icon size="24" color="natural darken-1">mdi-email</v-icon></v-list-item-avatar>
                 <v-list-item-avatar v-else size="40" color="primary lighten-1"><v-icon size="24" color="primary lighten-3">mdi-email-open</v-icon></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>

                <v-list-item-subtitle
                  v-text="item.body"
                ></v-list-item-subtitle>
                   <v-list-item-subtitle
                > {{ $dayjs(new Date(item.createDate) ).locale('th').format('DD MMMM YYYY HH:mm')  }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
                </v-list-item>
          </v-list>
      </v-card-text>
  </v-card>
</template>

<script>
import { postNewsLog } from "@/api/"
export default {
    props: {
        item: {
            type: Object
        },
    },
    methods: {
      goToDetails() {
        postNewsLog({newsId: this.item.id}, message => {
          console.log(message.data.code)
        }, error=> {
          console.log(error)
        })
        this.$router.push({name: 'message',params: {item: this.item}})
      }
    },
}
</script>

<style lang="scss" scoped>
.v-sheet.v-card {
  border-radius: 8px;
}
</style>